/**
  WARNING!!! This file should only be altered for boilerplate.
  Changes will be propagated to all sites.
**/

import * as React from "react";

/**
* Displays a single category for the blog
* Displays a selection of articles from a single blog category
*/

const Category = (props) => {
  return <div className='text-primary'>Category</div>;
};

export default Category;
